@import 'lms-ui/theme.scss';
.formationCard {
  max-width: 410px;
  width: 100%;

  @include mobile {
    width: auto;
  }

  .action {
    margin-top: 10px;
  }
}

.title {
  color: var(--color-chart-sevres-blue);
}
