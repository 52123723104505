@import 'lms-ui/theme.scss';

.gridFrameContainer {
  width: 100%;
  padding: 20px 0;
}

.mobileBlockHeader {
  margin-bottom: 20px;
  margin-top: 20px;
}