@import 'lms-ui/theme.scss';

.heroContainer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 20px;

  @include mobile {
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.title {
  font-family: Paris2024;
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 1px;
  text-align: left;
  color: var(--color-chart-white);
  text-transform: uppercase;
  padding: 180px 40px 10px 40px;

  @include mobile {
    font-size: 25px;
    line-height: 30px;
    padding: 170px 10px 20px 10px;
  }

  @include tablet {
    font-size: 50px;
    line-height: 50px;
    padding: 150px 10px 20px 10px;
  }
}

.blueIcon {
  position: relative;
  top: 0;
  right: 0;
  margin: auto;
}
