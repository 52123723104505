@import 'lms-ui/theme.scss';

.textAlignLeft {
  text-align: left;
}

.connectBlockTitle {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 70px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-chart-instit-blue);
  text-align: start;
  margin: 0;

  @include mobile {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: left;
  }
}

.blueText {
  color: var(--color-chart-sevres-blue);
}

.btnContainer {
  width: 100%;
}
.arrowConnect:hover {
  fill: var(--color-chart-sevres-blue-hover);
}
.arrowConnect {
  fill: var(--color-chart-sevres-blue);
}

.firstBlock {
  flex-basis: 30%;

  @include mobile {
    flex-basis: 100%;
  }
}

.containerTitle {
  width: 100%;
  margin-bottom: 0;
}

.lastBlock {
  width: 20%;
  height: 180px;

  @include mobile {
    height: 110px;
    width: 100%;
  }
}

.arrow {
  color: var(--color-chart-sevres-blue);
}

.aca {
  font-family: 'Paris2024';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  text-align: start;

  @include mobile {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
