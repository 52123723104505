@import 'lms-ui/theme.scss';

.blueText {
  color: var(--color-chart-sevres-blue);
}

.titleStyle {
  font-family: var(--font-family);
  font-size: 80px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 1px;
  text-align: left;
  color: var(--color-chart-instit-blue);
  text-transform: uppercase;
  display: flex;
  gap: 20px;
  margin-top: 20px;

  @include mobile {
    font-family: var(--font-family);
    display: flex;
    font-size: 35px;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 0;
    gap: 10px;
  }
}

.textCommon {
  font-family: Source Sans Pro;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;

  @include mobile {
    font-family: Source Sans Pro;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
  }
}
