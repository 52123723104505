@import 'lms-ui/theme.scss';

.faqFrame {
  display: flex;
  flex-direction: row;
}

.faqTitle {
  font-family: var(--font-family);
  font-size: 80px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 1px;
  text-align: left;
  color: var(--color-chart-instit-blue);
  text-transform: uppercase;

  @include mobile {
    font-family: var(--font-family);
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.smileyFrame {
  flex: 0 1 39%;
  @include mobile {
    display: none;
  }
}

.smileyFrameChildren {
  height: 100%;
}

.dotContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue {
  color: var(--color-chart-sevres-blue);
}
