@import 'lms-ui/theme.scss';

.headerFront {
  margin-bottom: 0px;
}

.adminLink {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  svg {
    margin-right: 5px;
  }
  // required to override ant typo
  margin-bottom: 0px !important;
}

.divider {
  background-color: var(--color-chart-instit-blue);

  @include mobile {
    margin-top: 10px;
  }
}

.switch {
  margin-left: 10px;
  margin-right: 20px;

  @include mobile {
    margin: 10px auto;
  }
}

.headerSwitch {
  @include over-mobile {
    display: inline-block;
  }
}

.navList {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;

  @include mobile {
    padding: 0;
    margin-left: 1em;
    margin-right: 1em;
  }

  .verticalDivider {
    height: 70px;
    margin-top: 0px;
    margin-right: 1em;
    background-color: var(--color-chart-instit-blue);

    @include mobile {
      height: 40px;
    }
  }

  .navLink {
    display: flex;
    align-items: center;
    margin-right: 1.5em;
    border-bottom: 0.4em solid transparent;
    height: 80px;

    @include mobile {
      margin-right: 0.5em;
      border-bottom: 0em solid transparent;
      border-top: 0.4em solid transparent;
      padding: 0.5em;
      padding-left: 0px;
    }

    .linkMenuContent {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0.3em;

      @include mobile {
        flex-direction: column;
        justify-content: space-around;
      }

      svg {
        color: var(--color-chart-instit-blue);

        @include mobile {
          margin-bottom: 0.3em;
        }
      }

      .linkText {
        font-family: var(--font-family);
        color: var(--color-chart-instit-blue);
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        margin-right: 0.3em;
        margin-left: 0.3em;

        @include mobile {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
  .active,
  .navLink:hover {
    border-bottom: 0.4em solid var(--color-chart-sevres-blue);

    @include mobile {
      border-bottom: 0em solid transparent;
      border-top: 0.4em solid var(--color-chart-sevres-blue);
    }
  }
}

.whiteNavLink {
  .navLink {
    * {
      color: var(--color-chart-white) !important;
    }
  }

  .verticalDivider {
    background-color: var(--color-chart-white);
  }
}

.avatar {
  display: flex;
  align-items: center;
}

.finishOnboardingButton {
  display: flex;
  align-items: center;
  margin-right: 1.5em;
  border-bottom: 0.4em solid transparent;
  height: 80px;

  @include mobile {
    margin-right: 0.5em;
    border-bottom: 0em solid transparent;
    border-top: 0.4em solid transparent;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5em;
    padding-left: 0px;
    height: auto;
  }
}
