.loginFooter {
  display: flex;
  height: var(--footer--height);
  background-color: var(--color-chart-white);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;

  .divider {
    height: 50px;
    width: 1px;
    min-width: 1px;
    background-color: var(--color-chart-instit-blue);
    margin-top: 10px;
  }

  .left {
    margin-left: 10px;
  }

  .right {
    margin-right: 10px;
  }

  .nav {
    width: 100%;
  }
}
