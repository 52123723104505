.logoContainer {
  height: 120px;
  margin: 10px 0px;
  width: 100%;

  .logoSection {
    width: 100%;
    margin: auto;
    justify-content: center;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 120px;
      width: 170px;

      img {
        height: 80px;
      }
    }
    .logoRight {
      padding-left: 20px;
    }
    .logoLeft {
      padding-right: 20px;
    }
  }
}

.context {
  margin-top: 1em;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-chart-sevres-blue);
  font-weight: 600;

  p {
    margin-bottom: 0em;
  }

  .contextTitle {
    color: var(--color-chart-instit-blue);
  }
}

.iconContainer {
  width: 100%;

  .centered {
    text-align: center;
    display: inline-block;
  }

  .topIcons {
    margin-top: 30px;
  }

  .bottomIcons {
    margin-top: 20px;
  }

  .icon {
    height: auto;
    max-width: 70px;
  }
}

.notLastChild {
  margin-right: 30px;
}

.text {
  text-align: justify;
  height: 130px;
  font-size: 12px;
}

.link {
  display: inline-grid;
  margin: 10px;
  width: stretch;

  .options {
    display: flex;
    margin: auto;
    text-align: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.optionText {
  font-size: 12px;
}

.lonelyOption {
  margin: 10px auto 0px auto;
}
