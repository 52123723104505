@import 'lms-ui/theme.scss';

.socialNetworkContainer {
  width: 100%;
}

.centerVertically {
  display: inline-grid;
  vertical-align: middle;
  box-sizing: content-box;
}

.youtube {
  flex-basis: 20%;
}

.facebook {
  flex-basis: 20%;
}

.instagram {
  flex-basis: 20%;
}

.linkedin {
  flex-basis: 40%;
}

.asset {
  height: 220px;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  .logo {
    padding: 20px 40px;
  }
}

.arch {
  height: 100%;
}

.icon {
  margin: auto;
  height: auto;
  max-width: 100px;
  width: 100%;
}

.content {
  height: 420px;
}

.context {
  margin-top: 10px;
  text-align: left;
  font-size: 26px;
  line-height: 26px;
  color: var(--color-chart-sevres-blue);
  font-weight: 600;
  white-space: pre-line;
  height: 180px;
  margin-bottom: 0px;

  @include under-large-desktop {
    font-size: 22px;
    line-height: 22px;
    margin-top: 0;
  }

  @include tablet {
    font-size: 18px;
    line-height: 18px;
    margin-top: 0;
  }

  .contextTitle {
    color: var(--color-chart-instit-blue);
  }
}

.link {
  display: flex;
  width: 100%;
  margin: 20px;
  height: 60px;

  .options {
    display: flex;
    justify-content: center;
    margin: auto;
    list-style-type: none;
  }
}

.optionContent {
  margin-right: 1.5em;
  margin-left: 1.5em;
}
