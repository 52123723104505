@import 'lms-ui/theme.scss';

.buttonLink {
  span {
    text-decoration-line: underline;
    color: var(--color-chart-sevres-blue);
  }
  margin-bottom: 20px;
}

.container {
  align-content: center;
}

.contact {
  color: var(--color-chart-sevres-blue);
}

.downloadLink {
  color: var(--color-chart-sevres-blue);
}
