@import 'lms-ui/theme.scss';

.formationCard {
  margin-bottom: $md-margin;
}

.formationList {
  @include mobile {
    width: 100%;
  }
}