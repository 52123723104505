@import 'lms-ui/theme.scss';

.primary {
  // Override Ant theme design
  color: var(--color-chart-white) !important;
  background-color: var(--color-chart-sevres-blue) !important;
  border: 1px solid var(--color-chart-sevres-blue) !important;
}

.primaryOutline {
  color: var(--color-chart-sevres-blue);
  background-color: var(--color-chart-white);
  border: 1px solid var(--color-chart-sevres-blue);
}

.primaryOutline:hover {
  color: var(--color-chart-white);
  background-color: var(--color-chart-sevres-blue);
  border: 1px solid var(--color-chart-sevres-blue);
}

.thematicButton {
  margin: 0.2rem 0.5rem;

  @include mobile {
    margin-left: 0rem;
  }
}

.buttonList {
  margin-bottom: 3rem;
  margin-left: 0rem;

  @include mobile {
    justify-content: space-between;
    margin-bottom: 1.5rem;
    overflow-x: scroll;
    white-space: nowrap;
  }
}