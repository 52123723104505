@import 'lms-ui/theme.scss';

.collapseContainer {
  margin-top: 40px;
  border: none;

  @include mobile {
    margin-top: 30px;
  }
}

.collapsePanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background: var(--color-chart-white);

  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
  border-radius: 5px !important;

  border: none;
  margin: 10px;
  > div {
    border: none;
    width: 100%;
    > span {
      text-align: left;
    }
  }
  > div:nth-child(2) > div {
    padding-top: 8px;
  }
}

.textCommon {
  font-family: Source Sans Pro;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  @include mobile {
    font-family: Source Sans Pro;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.question {
  color: var(--color-chart-instit-blue);
  font-weight: 700;
}

.response {
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
  td {
    border: solid 1px black;
    padding: 5px;
  }
}

.arrow > svg {
  width: 16px;
  height: 16px;
}
